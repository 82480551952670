import { scrollToSection } from '@elseu/sdu-titan-web-commerce';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

/**
 * A custom hook for resolving Next.js links with additional behavior.
 * @returns {(link: HTMLAnchorElement) => (() => void) | undefined} A function that takes an HTMLAnchorElement and adds custom behavior to it.
 */
export const useNextLinkResolver = (): ((link: HTMLAnchorElement) => void) => {
  const { push } = useRouter();

  return useCallback(
    (link: HTMLAnchorElement) => {
      const href = link.getAttribute('href');

      /** Don't use the router when the link is external or to the /shop */
      if (!href || href.startsWith('http') || href.startsWith('/shop')) {
        return;
      }

      const onClick = (event: MouseEvent) => {
        if (event.ctrlKey || event.metaKey) {
          return;
        }

        /** Scroll to anchor tag */
        if (href.startsWith('#')) {
          event.preventDefault();
          scrollToSection(href.slice(1));

          return;
        }

        /** Use the router */
        push(href);
      };

      link.addEventListener('click', onClick);

      return () => {
        link.removeEventListener('click', onClick);
      };
    },
    [push],
  );
};

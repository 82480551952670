export const dialogOptions = {
  /**
   * The language of the dialog.
   * @type {string}
   */
  lang: 'NL',
  /**
   * The title of the dialog.
   * @type {string}
   */
  title: 'Helaas ging er iets fout',
  /**
   * The subtitle of the dialog.
   * @type {string}
   */
  subtitle:
    'Ons team is op de hoogte gebracht van deze fout. Als u ons wil helpen deze fout in de toekomst te voorkomen, kunt u hier informatie voor ons achterlaten.',
  /**
   * The second subtitle of the dialog.
   * @type {string}
   */
  subtitle2:
    'Als u ervoor kiest om uw e-mailadres achter te laten, brengen wij u op de hoogte wanneer de fout verholpen is.',
  /**
   * The label for the name input field.
   * @type {string}
   */
  labelName: 'Uw naam',
  /**
   * The label for the email input field.
   * @type {string}
   */
  labelEmail: 'Uw e-mail',
  /**
   * The label for the comments input field.
   * @type {string}
   */
  labelComments: 'Wat gebeurde er voordat de foutmelding verscheen?',
  /**
   * The label for the close button.
   * @type {string}
   */
  labelClose: 'Sluiten',
  /**
   * The label for the submit button.
   * @type {string}
   */
  labelSubmit: 'Versturen',
  /**
   * The error message to display when the form submission fails.
   * @type {string}
   */
  errorGeneric:
    'Er ging iets mis met het versturen van het formulier. Probeer het alstublieft opnieuw.',
  /**
   * The error message to display when the form fields are not filled in correctly.
   * @type {string}
   */
  errorFormEntry: 'Niet alle velden zijn goed ingevuld. Probeer het alstublieft opnieuw.',
  /**
   * The success message to display when the form is submitted successfully.
   * @type {string}
   */
  successMessage: 'Wij hebben uw feedback ontvangen, bedankt!',
};

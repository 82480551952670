import { scrollToSection } from '@elseu/sdu-titan-web-commerce';
import Link from 'next/link';
import type { MouseEvent, ReactElement } from 'react';
import { cloneElement, useCallback } from 'react';

/**
 * A custom hook for wrapping Next.js Link components with additional behavior.
 * @returns {(link: ReactElement) => ReactElement} A function that takes a ReactElement and returns a modified ReactElement with the desired behavior.
 */
export const useNextLinkWrapper = () =>
  useCallback((link: ReactElement) => {
    const {
      props: { href, onClick, ...props },
      key,
    } = link;

    /** Don't use the router when the link is external or to the /shop */
    if (href.startsWith('http') || href.startsWith('/shop')) {
      return link;
    }

    /** Add a click handler to scroll to the section when the link starts with an anchor tag */
    if (href.startsWith('#')) {
      return cloneElement(link, {
        ...props,
        onClick: (event: MouseEvent<HTMLElement>) => {
          event.preventDefault();

          onClick?.(event);

          if (event.ctrlKey || event.metaKey) return;

          scrollToSection(href.slice(1));
        },
      });
    }

    return cloneElement(link, { ...props, as: Link, href, key: key ?? undefined });
  }, []);

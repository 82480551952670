import { ThemeProvider } from '@elseu/sdu-titan-web-commerce';
import * as Sentry from '@sentry/nextjs';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { geomanist } from 'utilities/fonts/geomanist';
import { sourceSansPro } from 'utilities/fonts/sourceSansPro';
import { dialogOptions } from 'utilities/sentry';
import { useNextLinkResolver } from 'utilities/useNextLinkResolver';
import { useNextLinkWrapper } from 'utilities/useNextLinkWrapper';

declare global {
  interface Window {
    dataLayer?: {
      push: (data: Record<string, unknown>) => void;
    };
  }
}

/**
 * Global styles for the application
 */
export const GlobalStyle = createGlobalStyle`
  :root {
    --font-geomanist: ${geomanist.style.fontFamily};
    --font-source-sans-pro: ${sourceSansPro.style.fontFamily};
  }
`;

/**
 * CustomApp is a custom Next.js App component.
 * It sets up the ThemeProvider from the Elseu SDU Titan library, and configures it to use custom design tokens, components, and Next.js Link integration.
 *
 * Sentry ErrorBoundary is used to catch and report errors with a user-friendly dialog.
 *
 * @param {AppProps} props - Props containing the current page component and its props.
 * @returns {JSX.Element} The wrapped Next.js application.
 */
const CustomApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { asPath } = useRouter();

  /**
   * Pushes a virtual pageview event to the dataLayer when the route changes.
   */
  useEffect(() => {
    window.requestAnimationFrame(() => {
      window.dataLayer?.push({
        event: 'virtualPageview',
        virtualPagePath: asPath,
        virtualPageTitle: document.title,
      });
    });
  }, [asPath]);

  return (
    <>
      <ThemeProvider
        fonts={{
          geomanist: 'var(--font-geomanist)',
          sourceSansPro: 'var(--font-source-sans-pro)',
        }}
        useLinkResolver={useNextLinkResolver}
        useLinkWrapper={useNextLinkWrapper}
      >
        <Sentry.ErrorBoundary showDialog dialogOptions={dialogOptions}>
          <GlobalStyle />
          <Component {...pageProps} />
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </>
  );
};

export default CustomApp;

// import { Partytown } from '@builder.io/partytown/react';
// import Head from 'next/head';
// <Head>
//   <Partytown debug={true} forward={['dataLayer.push', 'snowplow']} />
//   {/* eslint-disable-next-line @next/next/no-sync-scripts */}
//   <script
//     src="https://www.googletagmanager.com/gtm.js?id=GTM-WB475N8"
//     type="text/partytown"
//   />
// </Head>;
